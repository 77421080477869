$background: darkblue;
$paper: white;
$primary: #FFCE1D;
$primary_hover: #FFFF00;
$secondary: #007BFF;
$secondary_hover: #4e9ff5;
$text: black;
$disabled: #E0E0E0;
$disabled_text: #A6A6A6;
$icon_button: #5F6368;
$candidate_base: cyan;
$candidate_second: blue;
$hover: cyan;