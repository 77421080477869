@use '../../../sass/colors.scss';
@use '../../../sass/effects.scss';

.panel {
  background-color: colors.$paper;
  padding: 1em;
  margin: 1em 0;
  border-radius: effects.$border-radius;
  -webkit-box-shadow: effects.$box-shadow;
  box-shadow: effects.$box-shadow;

  .title {
    margin-top: 0px;
  }
}