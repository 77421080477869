@use '../../../../../sass/colors.scss';

.container {
  padding: 10px 16px;
  margin-left: 0px !important;
  margin-top: 0 !important;
  width: 100% !important;

  &:hover {
    background-color: colors.$hover;
  }

  .crown {
    color: colors.$primary;
  }

  .name,
  .name_banned,
  .check,
  .check_banned,
  .check_full {
    padding: 0 !important;
  }

  .name,
  .name_banned {
    text-align: left;
    word-wrap: break-word;
    vertical-align: middle;
  }

  .name_banned {
    color: lightgray;
    text-decoration: line-through;
  }

  .check,
  .check_banned,
  .check_full {
    text-align: right;
  }

  .check,
  .check_banned {
    color: lightgray;
  }

  .check_full {
    color: green;
  }
}