@use '../../../../sass/colors.scss';
@use '../../../../sass/effects.scss';

.navbar {
  margin-top: 0;
  margin-bottom: 3em;
  padding: .1em;
  background-color: colors.$paper;
  -webkit-box-shadow: effects.$box-shadow;
  box-shadow: effects.$box-shadow;
}