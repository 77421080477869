@use '../../../sass/colors.scss';
@use '../../../sass/effects.scss';

.winnersContainer {
  margin-left: -10px;

  .winner {
    background-color: colors.$candidate_base;
    padding: 1em;
    border-radius: effects.$border-radius;
    box-shadow: effects.$box-shadow;
    font-size: 1.5em;
    font-weight: 600;

    div {
      overflow: hidden;
    }
  }
}

.list {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0.5em 0;
  }
}

.title,
.list {
  text-align: left;
}

.crown {
  color: colors.$primary;
}